.menutop
{
    position:relative;
	background-size:100% 100%;
	background:url(../images/menutop.jpg);
	height:500px;
	background-position:center center;
    background-repeat: no-repeat;
	padding:50px 0;

}

.menuimg
{
    /* background:url(../images/venue.jpeg); */
    /* background-position: center center; */
    height:250px;
    /* width:250px; */
    border-radius:50%;
    box-shadow:0 0 2px 2px #000;
    /* border:1px solid #000; */
    margin:10px;
}
.menuimg img
{
   height:100%;
   width:100%;
   border-radius:50%;
}
.menuType
{
    display:flex;
    justify-content:center;
    align-items:center;

}
.menuType h2{
    font-size:1.3rem;
    font-weight:800;
    text-transform: uppercase;
    color:#a51c20;
    font-family:"Playfair Display", serif;
}
.menuCard
{
    display:flex;
    
}  
.menuDetail
{
    padding: 0 40px;
    text-align:justify;
}
.menuDetail h2
{
    font-size:1.3rem;
    font-weight:800;
    text-transform: uppercase;
    color:rgb(236, 204, 156);
    font-family:"Playfair Display", serif;
}
.menuRate
{
   width: 200px;
   height:50px;
   

}
.menuRate h2{
    font-size:1.3rem;
    font-weight:800;
    text-transform: uppercase;
    color:rgb(236, 204, 156);
    font-family:"Playfair Display", serif;
}
/* ///////////////////////////////////////////// Section 2 ////////////////////////////////////////////////// */

.FacilitiesSection2 {
    padding-top: 10vh;
}

.FacilitiesSection2-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align:center;
}

.FacilitiesSection2-heading h1 {
    font-family: var(---MainHeadingFont);
    color: var(---TheamColor01);
    font-size: 50px;
    letter-spacing: 2px;
    margin: 5px;
    text-transform: uppercase;
}

.FacilitiesSection2-heading h2 {
    font-family: "Playfair Display", serif;
    font-size: 40px;
    letter-spacing: 2px;
    margin: 5px;
    text-transform: uppercase;
}

.FacilitiesSection2-heading span {
    height: 15vh;
    width: 15vh;
    margin: 10px;
}

.FacilitiesSection2-heading span img {
    height: 100%;
    width: 100%;
}



.Facilities-Section2-tabpanel {
    margin: 20px;
    padding: 20px;
}

.nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
    justify-content: center;
    display: flex;
}

.Menu-Image-Con{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.Menu-Image{
    /* border: 1px solid blue; */
    margin: 20px;
    height: 140vh;
    width: 100vh;
    border-radius: 10px;
    box-shadow: 0px 0px 5px gray;
}

.Menu-Image img{
    height: 100%;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 5px gray;
}
@import url('https://fonts.googleapis.com/css2?family=MonteCarlo&display=swap');


section{
	padding:80px 0 50px 0;
	}
.titleSection
{
	padding:0 80px;
	text-align: center;
}
.titleSection h2
{font-size:30px;
	position:relative;
	text-align:center; line-height:1.5em; 
	padding:30px; 
	font-family:"Playfair Display", serif; text-transform:uppercase;letter-spacing: 2px; 
	color:#000;
}
  .titleSection h2:before {
	position: absolute;
	left: 0;
	bottom:10px;
	width: 60%;
	left:50%; margin-left:-30%;
	height: 1px;
	content: "";
	background-color: #777; z-index: 4;
  }
  .titleSection h2:after {
	position:absolute;
	width:40px; height:40px; left:50%; margin-left:-20px; bottom:-10px;
	content: '\00a7'; font-size:30px; line-height:40px; color:#fff;
	font-weight:400; z-index: 5;
	display:block;
	background-color:#b80000;
  }
  .titleSection span
{
	font-size:1.1rem;
	/* position:relative; */
	text-align:center; line-height:1.5em; 
	 text-transform:uppercase;letter-spacing: 2px; 
	color:#000;
}
	.titleSection p
{
	font-size:1.1rem;
	/* position:relative; */
	text-align:center; line-height:1.5em; 

	color:#ccc;
}
@media(max-width:900px) and (min-width:600px)
{
	.titleSection
{
	padding:10px 0;
	text-align: center;
}
.titleSection h2
{font-size:30px;
	position:relative;
	text-align:center; 
	line-height:1.5em; padding:45px 0; 
	font-family:"Playfair Display", serif; text-transform:uppercase;
	/* letter-spacing: 2px;  */
	color:#000;
}
  .titleSection h2:before {
	position: absolute;
	left: 0;
	bottom: 20px;
	width: 60%;
	left:50%; margin-left:-30%;
	height: 1px;
	content: "";
	background-color: #777; z-index: 4;
  }
  .titleSection h2:after {
	position:absolute;
	width:40px; height:40px; left:50%; margin-left:-20px; bottom:0px;
	content: '\00a7'; font-size:30px; line-height:40px; color:#fff;
	font-weight:400; z-index: 5;
	display:block;
	background-color:#b80000;
  }
  .titleSection span
{
	font-size:1.1rem;
	/* position:relative; */
	text-align:center; line-height:1.5em; 
	 text-transform:uppercase;letter-spacing: 2px; 
	color:#000;
}
	.titleSection p
{
	font-size:1.1rem;
	/* position:relative; */
	text-align:center; line-height:1.5em; 

	color:#ccc;
}
}
@media(max-width:500px) and (min-width:300px)
{
	.titleSection
{
	padding:0;
	text-align: justify;
}
.titleSection h2
{font-size:20px;
	position:relative;
	text-align:center;
	 line-height:1.5em; padding:45px 0; 
	font-family:"Playfair Display", serif; text-transform:uppercase;letter-spacing: 2px; 
	color:#000;
}
  .titleSection h2:before {
	position: absolute;
	left: 0;
	bottom: 20px;
	width: 60%;
	left:50%; margin-left:-30%;
	height: 1px;
	content: "";
	background-color: #777; z-index: 4;
  }
  .titleSection h2:after {
	position:absolute;
	width:40px; height:40px; left:50%; margin-left:-20px; bottom:0px;
	content: '\00a7'; font-size:30px; line-height:40px; color:#fff;
	font-weight:400; z-index: 5;
	display:block;
	background-color:#ab1e22;
  }
  .titleSection span
{
	font-size:1.1rem;
	/* position:relative; */
	text-align:justify; line-height:1.5em; 
	 text-transform:uppercase;
	 letter-spacing: 0; 
	color:#000;
}
	.titleSection p
{
	font-size:1.1rem;
	/* position:relative; */
	text-align:justify; line-height:1.5em; 

	color:#ccc;
}
}
@media(max-width:300px) and (min-width:100px)
{
	.titleSection
{
	padding:0;
	text-align: justify;
}
.titleSection h2
{font-size:20px;
	position:relative;
	text-align:center;
	 line-height:1.5em; padding:45px 0; 
	font-family:"Playfair Display", serif; text-transform:uppercase;letter-spacing: 2px; 
	color:#000;
}
  .titleSection h2:before {
	position: absolute;
	left: 0;
	bottom: 20px;
	width: 60%;
	left:50%; margin-left:-30%;
	height: 1px;
	content: "";
	background-color: #777; z-index: 4;
  }
  .titleSection h2:after {
	position:absolute;
	width:40px; height:40px; left:50%; margin-left:-20px; bottom:0px;
	content: '\00a7'; font-size:30px; line-height:40px; color:#fff;
	font-weight:400; z-index: 5;
	display:block;
	background-color:#ab1e22;
  }
  .titleSection span
{
	font-size:1.1rem;
	/* position:relative; */
	text-align:justify; line-height:1.5em; 
	 text-transform:uppercase;
	 letter-spacing: 0; 
	color:#000;
}
	.titleSection p
{
	font-size:1.1rem;
	/* position:relative; */
	text-align:justify; line-height:1.5em; 

	color:#ccc;
}
}
/* .titleSection
{
	padding:0 80px;
	text-align: center;
} */

.one h1{
	text-align: left;
	text-transform: uppercase;
	padding-bottom: 5px;
	font-size:25px;
	color:#ab1e22;
	font-weight:700;
	text-align: center;
  }
  .one h1:before {
	width: 28px;
	height: 5px;
	display: block;
	content: "";
	position: absolute;
	top:70%;
	bottom:0;
	left: 52%;
	margin-left: -14px;
	background-color: #b80000;
  }
  .one h1:after {
	width: 100px;
	height: 1px;
	display: block;
	content: "";
	position: relative;
	margin-top: 10px;
	left: 50%;
	bottom:0;
	top:0;
	margin-left: -50px;
	background-color: #b80000;
  }
  
@media(min-width:300px) and (max-width:500px)
{
	.one h2{
		text-align: left;
		text-transform: uppercase;
		padding-bottom: 5px;
	  }
	  .one h2:before {
		width: 28px;
		height: 5px;
		display: block;
		content: "";
		position: absolute;
		top:27%;
		bottom:0;
		left: 40%;
		margin-left: -14px;
		background-color: #b80000;
	  }
	  .one h2:after {
		width: 100px;
		height: 1px;
		display: block;
		content: "";
		position: relative;
		margin-top: 25px;
		left: 40%;
		bottom:0;
		top:0;
		margin-left: -50px;
		background-color: #b80000;
	  }
	  
}
.events
{
	padding:80px;
}

.eventTitle h2
{font-size:25px;
	position:relative;
	text-align:left; line-height:50px; 
	font-family:"Playfair Display", serif; text-transform:uppercase;letter-spacing: 2px; 
	color:#000;
}



.eventTitle h2:before {
	position: absolute;
	left: 0;
	bottom:0;
	width: 60%;
	left:0; 
	height: 1px;
	content: "";
	background-color: #777; z-index: 4;
  }
  /* .eventTitle h2:after {
	position:absolute;
	top:50%;
	width:40px; height:40px; left:30%;  bottom:0px;
	content: '\00a7'; font-size:30px; line-height:40px; color:#c50000;
	font-weight:400; z-index: 5;
	display:block;
	
  } */
  .eventTitle span
{
	font-size:1.1rem;
	/* position:relative; */
	text-align:left; line-height:1.5em; 
	 text-transform:uppercase;letter-spacing: 2px; 
	color:#000;
}
	.eventTitle p
{
	font-size:1rem;
	/* position:relative; */
	text-align:justify; 
padding:20px 0;
	color:#000;
}
.events .card{
	padding:30px;
}
.cardImg
{
	position:relative;
	width:100%;
	height:400px;
	border-radius: 10px;
	box-shadow: 0px 0px 5px gray;
	/* border:1px solid #000; */
}
.cardImg img
{
	width:100%;
	height:100%;
	border-radius: 10px;

	/* border:1px solid #000; */
}

.overlay
{width:200px;
	height:200px;
	border-radius:50%;
	position:absolute;
	top:70%;
	right:-18%;
	display:flex;
	justify-content:center;
	align-items:center;
	/* bottom:0; */
	/* left:0; */
	z-index:10;
	background:rgba(221, 217, 217, 0.8);
}
.overlay h2
{
	font-size:25px;
	font-weight:600;
	text-transform: uppercase;
	color:rgb(102, 94, 45);
	
}
@media(max-width:900px) and (min-width:600px)
{
	.events
{
	padding:20px;
}

.eventTitle h2
{font-size:25px;
	position:relative;
	text-align:left; line-height:50px; 
	font-family:"Playfair Display", serif; text-transform:uppercase;letter-spacing: 2px; 
	color:#000;
}



.eventTitle h2:before {
	position: absolute;
	left: 0;
	bottom:0;
	width: 60%;
	left:0; 
	height: 1px;
	content: "";
	background-color: #777; z-index: 4;
  }
  /* .eventTitle h2:after {
	position:absolute;
	top:50%;
	width:40px; height:40px; left:30%;  bottom:0px;
	content: '\00a7'; font-size:30px; line-height:40px; color:#c50000;
	font-weight:400; z-index: 5;
	display:block;
	
  } */
  .eventTitle span
{
	font-size:1.1rem;
	/* position:relative; */
	text-align:left; line-height:1.5em; 
	 text-transform:uppercase;letter-spacing: 2px; 
	color:#000;
}
	.eventTitle p
{
	font-size:1rem;
	/* position:relative; */
	text-align:justify; 
padding:20px 0;
	color:#000;
}
.events .card{
	padding:30px;
}
.cardImg
{
	position:relative;
	width:100%;
	height:300px;
	border:1px solid #000;
}
.cardImg img
{
	width:100%;
	height:100%;
	border:1px solid #000;
}

.overlay
{width:200px;
	height:200px;
	border-radius:50%;
	position:absolute;
	top:70%;
	right:-18%;
	display:flex;
	justify-content:center;
	align-items:center;
	/* bottom:0; */
	/* left:0; */
	z-index:10;
	background:rgba(221, 217, 217, 0.8);
}
.overlay h2
{
	font-size:25px;
	font-weight:600;
	text-transform: uppercase;
	color:rgb(102, 94, 45);
	
}

}
@media(max-width:768px) and (min-width:550px)
{
	.events
{
	padding:30px 10px;
}
.overlay
{width:200px;
	height:200px;
	border-radius:50%;
	position:absolute;
	top:70%;
	right:30%;
	display:flex;
	justify-content:center;
	align-items:center;
	/* bottom:0; */
	/* left:0; */
	z-index:10;
	background:rgba(221, 217, 217, 0.8);
}
}
@media(max-width:548px) and (min-width:481px)
{
	.events
{
	padding:30px 10px;
}
.overlay
{width:200px;
	height:200px;
	border-radius:50%;
	position:absolute;
	top:70%;
	right:30%;
	display:flex;
	justify-content:center;
	align-items:center;
	/* bottom:0; */
	/* left:0; */
	z-index:10;
	background:rgba(221, 217, 217, 0.8);
}

.eventTitle h2
{font-size:20px;
	position:relative;
	text-align:left; line-height:50px; 
	font-family:"Playfair Display", serif; text-transform:uppercase;letter-spacing: 2px; 
	color:#000;
}



.eventTitle h2:before {
	position: absolute;
	left: 0;
	bottom:0;
	width: 60%;
	left:0; 
	height: 1px;
	content: "";
	background-color: #777; z-index: 4;
  }
  /* .eventTitle h2:after {
	position:absolute;
	top:50%;
	width:40px; height:40px; left:30%;  bottom:0px;
	content: '\00a7'; font-size:30px; line-height:40px; color:#c50000;
	font-weight:400; z-index: 5;
	display:block;
	
  } */
  .eventTitle span
{
	font-size:1.1rem;
	/* position:relative; */
	text-align:left; line-height:1.5em; 
	 text-transform:uppercase;letter-spacing: 2px; 
	color:#000;
}
	.eventTitle p
{
	font-size:1rem;
	/* position:relative; */
	text-align:justify; 
padding:20px 0;
	color:#000;
}
.events .card{
	padding:30px;
}
.cardImg
{
	position:relative;
	width:100%;
	height:300px;
	border:1px solid #000;
}
.cardImg img
{
	width:100%;
	height:100%;
	border:1px solid #000;
}


}
@media(max-width:481px) and (min-width:300px)
{
	.events
{
	padding:30px 10px;
}
.overlay
{width:200px;
	height:200px;
	border-radius:50%;
	position:absolute;
	top:70%;
	right:10%;
	display:flex;
	justify-content:center;
	align-items:center;
	/* bottom:0; */
	/* left:0; */
	z-index:10;
	background:rgba(221, 217, 217, 0.8);
}
.overlay h2
{
	font-size:25px;
	font-weight:600;
	text-transform: uppercase;
	color:rgb(102, 94, 45);
	
}
.eventTitle h2
{font-size:20px;
	position:relative;
	text-align:left; line-height:50px; 
	font-family:"Playfair Display", serif; text-transform:uppercase;letter-spacing: 2px; 
	color:#000;
}



.eventTitle h2:before {
	position: absolute;
	left: 0;
	bottom:0;
	width: 60%;
	left:0; 
	height: 1px;
	content: "";
	background-color: #777; z-index: 4;
  }
  /* .eventTitle h2:after {
	position:absolute;
	top:50%;
	width:40px; height:40px; left:30%;  bottom:0px;
	content: '\00a7'; font-size:30px; line-height:40px; color:#c50000;
	font-weight:400; z-index: 5;
	display:block;
	
  } */
  .eventTitle span
{
	font-size:1.1rem;
	/* position:relative; */
	text-align:left; line-height:1.5em; 
	 text-transform:uppercase;letter-spacing: 2px; 
	color:#000;
}
	.eventTitle p
{
	font-size:1rem;
	/* position:relative; */
	text-align:justify; 
padding:20px 0;
	color:#000;
}
.events .card{
	padding:30px;
}
.cardImg
{
	position:relative;
	width:100%;
	height:300px;
	border:1px solid #000;
}
.cardImg img
{
	width:100%;
	height:100%;
	border:1px solid #000;
}


}
@media(max-width:300px) and (min-width:100px)
{
	.luxurious
{
	padding:10px;
	height:auto;
	
}
	.events
{
	padding:30px 10px;
}

.overlay
{width:120px;
	height:120px;
	border-radius:50%;
	position:absolute;
	top:70%;
	right:10%;
	display:flex;
	justify-content:center;
	align-items:center;
	/* bottom:0; */
	/* left:0; */
	z-index:10;
	background:rgba(221, 217, 217, 0.8);
}
.overlay h2
{
	font-size:16px;
	font-weight:600;
	text-transform: uppercase;
	color:rgb(102, 94, 45);
	
}
.eventTitle h2
{font-size:20px;
	position:relative;
	text-align:left; line-height:50px; 
	font-family:"Playfair Display", serif; text-transform:uppercase;letter-spacing: 2px; 
	color:#000;
}



.eventTitle h2:before {
	position: absolute;
	left: 0;
	bottom:0;
	width: 60%;
	left:0; 
	height: 1px;
	content: "";
	background-color: #777; z-index: 4;
  }
  /* .eventTitle h2:after {
	position:absolute;
	top:50%;
	width:40px; height:40px; left:30%;  bottom:0px;
	content: '\00a7'; font-size:30px; line-height:40px; color:#c50000;
	font-weight:400; z-index: 5;
	display:block;
	
  } */
  .eventTitle span
{
	font-size:1.1rem;
	/* position:relative; */
	text-align:left; line-height:1.5em; 
	 text-transform:uppercase;letter-spacing: 2px; 
	color:#000;
}
	.eventTitle p
{
	font-size:1rem;
	/* position:relative; */
	text-align:justify; 
padding:20px 0;
	color:#000;
}
.events .card{
	padding:30px;
}
.cardImg
{
	position:relative;
	width:100%;
	height:300px;
	border:1px solid #000;
}
.cardImg img
{
	width:100%;
	height:100%;
	border:1px solid #000;
}


}
/* --------------------------------luxurious-------------------------- */

.flist
{
	padding:0 40px;
}
.flist li {
	list-style:square;
	font-size:2rem;
	color:#b80000;
}
.flist li i {
	font-size:1.2rem; 
	font-weight:600;
	color:#000;
}

.flist li span{
	font-size:1.2rem; 
	font-weight:600;
	color:#000;
	font-family:"Playfair Display", serif;
}
.flist li p{
	font-size:1rem; 
	text-align:justify;
	color:rgb(53, 52, 52);
}
@media(max-width:900px) and (min-width:500px)
{
	.luxurious
{
	padding:10px;
	height:auto;
	
}
.flist
{
	padding:0 20px;
}
.flist li {
	list-style:square;
	font-size:2rem;
	color:#ab1e22;
}
.flist li i {
	font-size:1.2rem; 
	font-weight:600;
	color:#000;
}

.flist li span{
	font-size:1.2rem; 
	font-weight:600;
	color:#000;
	font-family:"Playfair Display", serif;
}
.flist li p{
	font-size:.8rem; 
	text-align:justify;
	color:rgb(53, 52, 52);
}
}
@media(max-width:500px) and (min-width:300px)
{
	.luxurious
{
	padding:10px;
	height:auto;
	
}
.flist
{
	padding:0 20px;
}
.flist li {
	list-style:square;
	font-size:2rem;
	color:#ab1e22;
}
.flist li i {
	font-size:1.2rem; 
	font-weight:600;
	color:#000;
}

.flist li span{
	font-size:1.2rem; 
	font-weight:600;
	color:#000;
	font-family:"Playfair Display", serif;
}
.flist li p{
	font-size:.8rem; 
	text-align:justify;
	color:rgb(53, 52, 52);
}
}
/* -------------------------------------------innovative---------------------- */
.innovative
{position:relative;
	/* background-size:100% 100%; */
	background:url(../images/gallery/20.jpg);
	background-repeat: no-repeat;
	background-size:cover;
	background-attachment: fixed;
	height:550px;
	background-position:center center;
	/* padding:50px 0; */

}
.innovDetail
{position:absolute;
	right:50px;
	width:100%;
	height:auto;
	top:40px;
	border-radius:5px;
	border-bottom:2px solid rgb(250, 212, 116);
	background:#ffffff;
	padding:50px;
	box-shadow: 0px 0px 5px gray;
	
}

.innovDetail h1
{
	font-size:25px;
	position:relative;
	
	 /* line-height:1.5em; */
	font-family:"Playfair Display", serif; 
	text-transform:uppercase;
	letter-spacing: 2px; 
	color:#000;
}
.innovDetail p
{
	font-size:1.1rem;
	text-align:justify;
	color:#000;
}
@media(max-width:1024px) and (min-width:768px)
{
	.innovative
{position:relative;
	background-size:100% 100%;
	background:url(../images/blueberry-mojito.jpeg);
	height:500px;
	background-position:center center;
	padding:50px 0;

}
.innovDetail
{position:absolute;
	right:0;
	width:100%;
	height:auto;
	top:10px;
	border-radius:5px;
	border-bottom:2px solid rgb(250, 212, 116);
	background:#ffffff;
	padding:20px;
	
}
.innovDetail h2
{
	font-size:20px;
	position:relative;
	
	 /* line-height:1.5em; */
	font-family:"Playfair Display", serif; 
	text-transform:uppercase;
	letter-spacing: 2px; 
	color:#000;
}
.innovDetail p
{
	font-size:1rem;
	text-align:justify;
	color:#000;
}
}
@media(max-width:768px) and (min-width:481px)
{
	.innovative
{position:relative;
	background-size:100% 100%;
	background:url(../images/blueberry-mojito.jpeg);
	height:500px;
	background-position:center center;
	padding:50px 0;

}
.innovDetail
{position:absolute;
	right:0;
	width:70%;
	height:370;
	top:10px;
	border-radius:5px;
	border-bottom:2px solid rgb(250, 212, 116);
	background:#ffffff;
	padding:20px;
	
}
.innovDetail h2
{
	font-size:20px;
	position:relative;
	
	 /* line-height:1.5em; */
	font-family:"Playfair Display", serif; 
	text-transform:uppercase;
	letter-spacing: 2px; 
	color:#000;
}
.innovDetail p
{
	font-size:1rem;
	text-align:justify;
	color:#000;
}
}
@media(max-width:768px)and(min-width:481px)
{
	.innovDetail
{position:absolute;
	right:50px;
	width:100%;
	height:450px;
	top:40px;
	border-radius:5px;
	border-bottom:2px solid rgb(250, 212, 116);
	background:#ffffff;
	padding:40px;
	box-shadow: 0px 0px 5px gray;
	
}
.innovDetail h2
{
	font-size:16px;
	position:relative;
	
	 /* line-height:1.5em; */
	font-family:"Playfair Display", serif; 
	text-transform:uppercase;
	letter-spacing: 2px; 
	color:#000;
}
}
@media(max-width:500px) and (min-width:300px)
{
	.innovative
{position:relative;
	background-size:100% 100%;
	background:url(../images/blueberry-mojito.jpeg);
	height:550px;
	background-position:center center;
	padding: 0;

}
.innovDetail
{position:absolute;
	right:0;
	width:95%;
	height:auto;
	top:40px;
	border-radius:5px;
	border-bottom:2px solid rgb(250, 212, 116);
	background:#ffffff;
	padding:20px;
	
}
.innovDetail h2
{
	font-size:20px;
	position:relative;
	
	 /* line-height:1.5em; */
	font-family:"Playfair Display", serif; 
	text-transform:uppercase;
	letter-spacing: 2px; 
	color:#000;
}
.innovDetail p
{
	font-size:1rem;
	text-align:justify;
	color:#000;
}
}


.facilitiesSection
{
	background:url(../images/food-platter.jpg);
	padding:100px;
}


.facilitiesSection .card {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background:none;
  border-bottom:1px solid #fff;
  border-left:1px solid #fff;
  /* box-shadow: 0 0 1px 1px #C0C0C0; */
  min-height: 100%;
 
}

.facilitiesSection .cardTitle h2 {
  padding: 10px;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color:whitesmoke;
}

.facilitiesSection .cardTitle p {
  font-size: 1rem;
  text-align: center;
  text-transform: capitalize;
  color:#ccc;
}

.facilitiesSection .card-img-top {
  width: 50px;
  height: 50px;
}

.facilitiesSection .card-img-top img {
  width: 100%;
  height: 100%;
}

@media(max-width:900px) and (min-width:600px)
{.facilitiesSection
	{
		background:url(../images/food-platter.jpg);
		padding:10px;
	}
	
	
	.facilitiesSection .card {
	  padding: 10px;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  background:none;
	  border-bottom:1px solid #fff;
	  border-left:1px solid #fff;
	  /* box-shadow: 0 0 1px 1px #C0C0C0; */
	  min-height: 100%;
	 
	}
	
	.facilitiesSection .cardTitle h2 {
	  padding: 10px;
	  font-size: 1.3rem;
	  font-weight: 700;
	  text-align: center;
	  text-transform: uppercase;
	  color:rgb(236 204 156);
	}
	
	.facilitiesSection .cardTitle p {
	  font-size: 1rem;
	  text-align: center;
	  text-transform: capitalize;
	  color:#ccc;
	}
	
	.facilitiesSection .card-img-top {
	  width: 50px;
	  height: 50px;
	}
	
	.facilitiesSection .card-img-top img {
	  width: 100%;
	  height: 100%;
	}}

	@media(max-width:500px) and (min-width:300px)
	{.facilitiesSection
		{
			background:url(../images/food-platter.jpg);
			padding:10px;
		}
		
		
		.facilitiesSection .card {
		  padding: 10px;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  background:none;
		  border-bottom:1px solid #fff;
		  border-left:1px solid #fff;
		  /* box-shadow: 0 0 1px 1px #C0C0C0; */
		  min-height: 100%;
		 
		}
		
		.facilitiesSection .cardTitle h2 {
		  padding: 10px;
		  font-size: 1.3rem;
		  font-weight: 700;
		  text-align: center;
		  text-transform: uppercase;
		  color:rgb(236 204 156);
		}
		
		.facilitiesSection .cardTitle p {
		  font-size: 1rem;
		  text-align: center;
		  text-transform: capitalize;
		  color:#ccc;
		}
		
		.facilitiesSection .card-img-top {
		  width: 50px;
		  height: 50px;
		}
		
		.facilitiesSection .card-img-top img {
		  width: 100%;
		  height: 100%;
		}}
.section-title 
{
/* display:flex;
justify-content: center;
align-items:center;
flex-direction: column;
text-align:justify; */
padding:20px;
}
.section-title h2 
{
 
  
font-size:30px;
}
.bookTab
{
  border:1px dotted #808080;
  display:flex;
  justify-content: center;
  align-items: center;
  /* padding:10px; */
  margin-right:20px;
  
}
@media(max-width:966px) and (min-width:481px)
{
	.bookTab
{
	padding-top:10px;
  border:1px dotted #808080;
  display:flex;
  justify-content: center;
  align-items: center;
  /* padding:10px; */
  flex-direction:column;
  margin-right:20px;
  
}
}
.bookTitle
{
	padding-top:10px;
	margin-left:10px;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media(max-width:1200px) and (min-width:500px)
{
	.bookTitle
{
	padding-top:10px;
	margin-left:10px;
  display:flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
}
}
.bookTitle h2
{
	color:#000;
  font-size:1rem;
  
}
.contact
{
	width:50px;
	height:50px;
}
.contact img
{
	width:100%;
	height:100%;
}
.bookTitle p
{
	color:#000;
  font-size:1rem;
  
}
.SwiperSlide
{
	position:relative;
}
.bannerText
{
	position:absolute;
	transform:translate(10%,30%);
	left:0;
	right:0;
	top:0;
	bottom:0;
	max-width:385px;
	height:50vh;
	padding:10px;
	/* border-radius:5px;
	box-shadow:0 0 2px 2px #c0c0c0; */
	display:flex;
	justify-content: center;
	align-items: center;
	text-align: left;
	background:linear-gradient(to right, rgba(243, 149, 149, 0.8), rgba(224, 223, 223, 0.8));
}
/* .bannerText h1
{
	text-align: center;
	
	font-family:"Playfair Display", serif;
	font-size:49px;
	font-weight:800;
	letter-spacing: 2px;
	color:#ab1e22;
} */
.bannerText p
{
	font-family:"Playfair Display", serif;
	font-size:30px;
	text-align: center;
	/* letter-spacing: 1em; */
	color:#ab1e22;
}
.swiper {
 	position:relative;
  width: 100%;
  height: 88vh;
}
.swiper-wrapper
{
	height:90vh;
}
.swiper-slide {
	position:absolute;
  text-align: center;
  font-size: 18px;
  background: #fff;
height:90vh;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media(min-width:900px) and (max-width:700px)
{
	.bannerText
{
	position:absolute;
	transform:translate(5%,50%);
	left:0;
	right:0;
	top:0;
	bottom:0;
	width:50vh;
	height:50vh;
	/* border-radius:5px;
	box-shadow:0 0 2px 2px #c0c0c0; */
	display:flex;
	justify-content: center;
	align-items: center;
	text-align: left;
	/* background:linear-gradient(to right, rgba(243, 149, 149, 0.8), rgba(224, 223, 223, 0.8)); */
}
.bannerText h2
{
	/* font-family: 'MonteCarlo', cursive; */
	font-family:"Playfair Display", serif;
	font-size:30px;
	font-weight:800;
	/* letter-spacing: 1.em; */
	color:#fff;
	text-align:justify;
}
.bannerText p
{
	
	font-size:16px;
	text-align:justify;
	/* letter-spacing: 1.em; */
	color:#fff;
}
}

@media(min-width:300px) and (max-width:500px)
{
	.bannerText
{
	position:absolute;
	transform:translate(5%,50%);
	left:0;
	right:0;
	top:0;
	bottom:0;
	width:50vh;
	height:50vh;
	/* border-radius:5px;
	box-shadow:0 0 2px 2px #c0c0c0; */
	display:flex;
	justify-content: center;
	align-items: center;
	text-align: left;
	/* background:linear-gradient(to right, rgba(243, 149, 149, 0.8), rgba(224, 223, 223, 0.8)); */
}
.bannerText h2
{
	/* font-family: 'MonteCarlo', cursive; */
	font-family:"Playfair Display", serif;
	font-size:20px;
	font-weight:800;
	/* letter-spacing: 1.em; */
	color:#fff;
	text-align:justify;
}
.bannerText p
{
	
	font-size:16px;
	text-align: justify;
	/* letter-spacing: 1.em; */
	color:#fff;
}
}


/*  2. About Area
/*----------------------------------------*/
.about-area{
  position: relative;
 
}
/* .about-content{margin-left: 40px} */
.about-image {
  background:#eee;
  /* background: url('images/yummy.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; */
  height:450px;
  left: 0;
  /* position: absolute; */
  top: 0;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 5px gray;
}
.about-image img
{
	width:100%;
	height:100%;
	border-radius: 10px;
}
.about-image.three{
  background:#000;}
.mc-form {
position: relative;
}
@media(max-width:500px)
{
	.about-image {
		background:#eee;
		/* background: url('images/yummy.jpg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover; */
		height:300px;
		left: 0;
		/* position: absolute; */
		top: 0;
		width: 100%;
	  }
	  .about-image img
	  {
		  width:100%;
		  height:100%;
	  }
}
.center {
	text-align: center;
}

.navbar{
    /* background-color: rgb(237 237 237); */
    /* position: absolute; */
    width: 100%;
}
.nav-link
{margin-left:20px;
    font-size:20px;
    font-weight:600;
    text-transform: uppercase;
	font-family:"Playfair Display", serif;
}
.navbar-collapse {
    justify-content: center;
}
.navbar-brand {
	height: 60px;
    width: 100px;
	
}
.navbar-brand img{
    height: 100%;
    width: 100%;
	transform:scale(1.5,2);
}

.navbar-nav{
	padding:20px 10px;
    width: 100%;
    justify-content: end;
}

/* .navbar-nav li{

} */
.navbar-nav li a{
    list-style: none;
    text-decoration: none;
    margin:5px 20px;
    color: #b80000;    
    font-family:"Playfair Display", serif;
    font-weight: 600;
    font-size: 18px;
	text-transform: uppercase;
}
.navbar-toggler {
    border-color: rgb(247, 194, 146);
}
 .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(9,55,244, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.swiper-button-next,
.swiper-button-prev {
    top:50%;
    background-color: #ab1e22;
    /* background:rgba(255, 255, 255, 0.5); */
    right: 10px;
    padding: 20px;
    color:#eee !important;
    fill: #eee  !important;
    stroke: #eee !important;
    backdrop-filter: blur(5px);
    border-radius: 5px;
}
.swiper-button-next:after,
.swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 1.4rem !important;
    text-transform: none!important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
}


.enquireButton {
	position: fixed;
	top:50%;
	transform: rotate( 90deg);
	background:#ab1e22;
	right: -35px;
	border-radius: 5px;
	color: #fff;
	padding: 10px 20px;
	text-decoration: none;
	z-index: 58;
	border:none;
}

.enquiry_close {
	background: peru;
}

@media(max-width:600px) {
	.enquireButton {
		height: 35px;
		right: -35px;
		font-size: 12px;
	}
}





.abouttop
{
    position:relative;
	background-size:100% 100%;
	background:url(../images/venue.jpeg);
	height:500px;
    background-repeat: no-repeat;
	background-position:center center;
	padding:50px 0;

}
.rounded-circle
{
    width:100px;
    height:100px;
    border-radius: 50%;
}
.ourStory
{
    background:#eee;
    padding-top:80px;
}
.about-Detail
{
    background:#fff;
    height:450px;
    padding:80px;
text-align:justify;
}
.about-Detail h2
{font-size:25px;
	position:relative;
	text-align:left;  
	font-family:"Playfair Display", serif; letter-spacing: 2px; 
	color:#000;
    
}

@media(max-width:900px) and (min-width:600px)
{
    .abouttop
{
    position:relative;
	background-size:100% 100%;
	background:url(../images/venue.jpeg);
	height:500px;
	background-position:center center;
	padding:50px 0;

}

.ourStory
{
    background:#eee;
    padding-top:25px;
    height:auto;
}
.about-Detail
{
    background:#fff;
    height:450px;
    padding:60px;
text-align:justify;
}
.about-Detail h2
{font-size:25px;
	position:relative;
	text-align:left;  
	font-family:"Playfair Display", serif; letter-spacing: 2px; 
	color:#000;
    
}
}
@media(max-width:500px) and (min-width:300px)
{
    .abouttop
{
    position:relative;
	background-size:100% 100%;
	background:url(../images/venue.jpeg);
	height:500px;
	background-position:center center;
	padding: 0;

}

.ourStory
{
    background:#eee;
    padding-top:80px;
}
.about-Detail
{
    background:#fff;
    height:auto;
    padding:20px;
text-align:justify;
}
.about-Detail h2
{font-size:25px;
	position:relative;
	text-align:left;  
	font-family:"Playfair Display", serif; letter-spacing: 2px; 
	color:#000;
    
}
}
.best
{
    padding:50px 10px;
}
/* <=============================================testimonial===================================> */

.testimonial{
    height: auto;
    overflow-x: hidden;
    padding: 10px 50px;
    background-attachment: fixed;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(to right, rgba(24, 24, 24, 0.8), rgba(15, 15, 15, 0.4)), url(../images/gallery/18.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 70%;
    overflow-y:hidden;

}
.testimonial .swiper-slide
{
    background:none;
}
.testimonial4_slide{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 70%;
    margin: auto;
    padding: 20px;
    position: relative;
    text-align: center;
  }
  .testimonial4_slide img {
      top: 0;
      left: 0;
      right: 0;
      width: 136px;
      height: 136px;
      margin: auto;
      display: block;
      color: #f2f2f2;
      font-size: 18px;
      line-height: 46px;
      text-align: center;
      position: relative;
      border-radius: 50%;
      box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
      -moz-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
      -o-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
      -webkit-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  }
  .testimonial4_slide p {
      color: #ffffff;
      font-size: 20px;
      line-height: 1.4;
      margin: 40px 0 20px 0;
  }
  .testimonial4_slide h4 {
    color: #ffffff;
    font-size: 22px;
  }
  @media(max-width:900px) and (min-width:300px)
  {
    .testimonial{
        height: auto;
        overflow-x: hidden;
        padding: 10px 50px;
        background-attachment: fixed;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: linear-gradient(to right, rgba(24, 24, 24, 0.8), rgba(15, 15, 15, 0.8)), url(../images/blueberry-mojito.jpeg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 70%;
        overflow-y:hidden;
    
    }
    .testimonial .swiper-slide
    {
        background:none;
    }
    .testimonial4_slide{
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 70%;
        margin: auto;
        padding: 20px;
        position: relative;
        text-align: center;
      }
      .testimonial4_slide img {
          top: 0;
          left: 0;
          right: 0;
          width: 136px;
          height: 136px;
          margin: auto;
          display: block;
          color: #f2f2f2;
          font-size: 18px;
          line-height: 46px;
          text-align: center;
          position: relative;
          border-radius: 50%;
          box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
          -moz-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
          -o-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
          -webkit-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
      }
      .testimonial4_slide p {
          color: #ffffff;
          font-size: 20px;
          line-height: 1.4;
          margin: 40px 0 20px 0;
      }
      .testimonial4_slide h4 {
        color: #ffffff;
        font-size: 22px;
      }
      
  }
  @media(max-width:500px) and (min-width:300px)
  {
    .testimonial{
        height: auto;
        overflow-x: hidden;
        padding: 10px;
        background-attachment: fixed;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: linear-gradient(to right, rgba(24, 24, 24, 0.8), rgba(15, 15, 15, 0.8)), url(../images/blueberry-mojito.jpeg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 70%;
        overflow-y:hidden;
    
    }
    .testimonial .swiper-slide
    {
        background:none;
    }
    .testimonial4_slide{
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        margin: auto;
        padding: 20px;
        position: relative;
        text-align: center;
      }
      .testimonial4_slide img {
          top: 0;
          left: 0;
          right: 0;
          width: 136px;
          height: 136px;
          margin: auto;
          display: block;
          color: #f2f2f2;
          font-size: 18px;
          line-height: 46px;
          text-align: center;
          position: relative;
          border-radius: 50%;
          box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
          -moz-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
          -o-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
          -webkit-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
      }
      .testimonial4_slide p {
          color: #ffffff;
          font-size: 1rem;
          line-height: 1.4;
          margin: 10px 0 10px 0;
          text-align:justify;
          
      }
      .testimonial4_slide h4 {
        color: #ffffff;
        font-size: 22px;
      }
      
  }
  .social-link {
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    border-radius: 50%;
    transition: all 0.3s;
    font-size: 0.9rem;
}

.social-link:hover, .social-link:focus {
    background: #ddd;
    text-decoration: none;
    color: #555;
}

.servicetop {
    position: relative;
    background-size: 100%;

    background: url(../images/service.jpg);
    height: 500px;
    /* background-repeat: no-repeat; */
    /* background-position:100%; */
    padding: 50px 0;

}
.rowDesign
{
	display:flex;
	justify-content: center;
	align-items: center;
}

.slist {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: right;
    height:400px;
    min-height:100%;
    border-bottom:1px solid red;
}

.slist-right {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: left;
    height:400px;
    min-height:100%;
    border-bottom:1px solid red;
}
@media(max-width:481px) and (min-width:300px)
{
  
    .slist {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        text-align: right;
        height:auto;
        /* min-height:100%; */
        border-bottom:1px solid red;
    }
    .slist-right {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        text-align: left;
        height:auto;
        /* min-height:100%; */
        border-bottom:1px solid red;
    }
}
.sdetail span {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000;
    font-family: "Playfair Display", serif;
    text-transform: uppercase;
}

.sdetail p {
    font-size: 1.2rem;
    text-align: justify;
    color: rgb(53, 52, 52);
}

.snum {
    width: 70px;
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;

}

.snum h2 {
    font-family: "Playfair Display", serif;
    color: #ccc;
    font-size: 2.5rem;
    font-weight: 700;
}

.snum h2:hover {
    color: gold;
    font-size: 2.5rem;
}

.simg {
    background: url(../images/gallery/46.jpg);
    background-position: center center;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px grey;
    margin: -60px 10px 10px 10px;
}
@media(max-width:800px)and (min-width:500px)
{
    .rowDesign
{
	display:flex;
	justify-content: center;
	align-items: center;
    flex-direction:column;
}
}


.bookSection {
    height: auto;
    overflow-x: hidden;
    padding: 30px 50px;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(to right, rgba(104, 102, 102, 0.8), rgba(97, 96, 96, 0.8)), url(../images/blueberry-mojito.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 70%;
    overflow-y: hidden;


}

.bookSection .titleSection {
    padding: 30px 80px;
    text-align: center;
}

.bookSection .titleSection h2 {
    font-size: 48px;
    position: relative;
    text-align: center;
    line-height: 1.5em;
    padding: 45px 0;
    font-family: "Playfair Display", serif;
    text-transform: capitalize;
    letter-spacing: 2px;
    color: #fff;
    font-weight: 600;
}

.bookSection .titleSection h2:before {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 60%;
    left: 50%;
    margin-left: -30%;
    height: 1px;
    content: "";
    background-color: #fff;
    z-index: 4;
}

.bookSection .titleSection h2:after {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 50%;
    margin-left: -20px;
    bottom: 0px;
    content: '';
    font-size: 30px;
    line-height: 40px;
    color: #c50000;
    font-weight: 400;
    z-index: 5;
    display: block;
    background-color: #f8f8f8;
}

.bookSection .titleSection span {
    font-size: 1.1rem;
    /* position:relative; */
    text-align: center;
    line-height: 1.5em;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #fff;
}

.bookSection .titleSection p {
    font-size: 50px;
    /* position:relative; */
    text-align: center;
    line-height: 1.5em;

    color: #fff;
}

.bookBtn {
    width: 250px;
    height: 60px;
    border: none;
    background: #a51c20;
    margin: 50px 0;
    border-radius: 5px;

}

.bookBtn h3 {
    color: #fff;
    font-size: 1rem;
    text-transform: uppercase;
}

.swiper-pagination-bullet-active {
    background: #a51c20;
}

/* .Service1 {
    transform: translateX(100px);
} */
/* 
.Service2 {
    transform: translateX(-100px);
} */

@media(max-width:500px) and (min-width:300px) {
    .bookSection {
        height: auto;
        overflow-x: hidden;
        padding: 20px;
        background-attachment: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: linear-gradient(to right, rgba(104, 102, 102, 0.8), rgba(97, 96, 96, 0.8)), url(../images/blueberry-mojito.jpeg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 70%;
        overflow-y: hidden;


    }

    .bookSection .titleSection {
        padding: 0;
        text-align: center;
    }

    .bookSection .titleSection h2 {
        font-size: 20px;
        position: relative;
        text-align: center;
        line-height: 1.5em;
        padding: 45px 0;
        font-family: "Playfair Display", serif;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #fff;
        font-weight: 600;
    }

    .bookSection .titleSection h2:before {
        position: absolute;
        left: 0;
        bottom: 20px;
        width: 60%;
        left: 50%;
        margin-left: -30%;
        height: 1px;
        content: "";
        background-color: #fff;
        z-index: 4;
    }

    .bookSection .titleSection h2:after {
        position: absolute;
        width: 40px;
        height: 40px;
        left: 50%;
        margin-left: -20px;
        bottom: 0px;
        content: '';
        font-size: 30px;
        line-height: 40px;
        color: #c50000;
        font-weight: 400;
        z-index: 5;
        display: block;
        background-color: #f8f8f8;
    }

    .bookSection .titleSection span {
        font-size: 1.1rem;
        /* position:relative; */
        text-align: center;
        line-height: 1.5em;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #fff;
    }

    .bookSection .titleSection p {
        font-size: 50px;
        /* position:relative; */
        text-align: center;
        line-height: 1.5em;

        color: #fff;
    }

    .bookBtn {
        width: 150px;
        height: 40px;
        border: none;
        background: rgb(250 212 116);
        margin: 50px 0;
        border-radius: 5px;

    }

    .bookBtn h3 {
        color: #fff;
        font-size: 1rem;
        text-transform: uppercase;
    }
}

@media(max-width:430px) {
    .Service1 {
        transform: translateX(0px);
    }

    .Service2 {
        transform: translateX(0px);
    }

    .simg {
        margin: 0px;
        width: 80%;
    }
}

@media(max-width:380px){
    .simg {
        height: 250px;
    }
}
@media(max-width:320px){
    .simg {
        height: 200px;
    }
}